import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import { SectionHomepageContainer } from '@components/Layout/Layout.styles';

import play from '@assets/Homepage/play.svg';
import { PATHS } from '@global/urls';

import {
  CloseIcon,
  ContentImage,
  Icon,
  Subtitle,
  Title,
  Wrapper,
  WrapperBackground,
  WrapperIcon,
  WrapperText,
  WrapperVideo,
} from './OurMission.styles';
import { IOurMissionProp } from './types';

const OurMision = (props: IOurMissionProp) => {
  const { handleTrackPlayVideo } = props;
  const { t } = useTranslation();
  const [playVideo, setPlayVideo] = useState(false);

  const handleClose = () => {
    handleTrackPlayVideo(false);
    setPlayVideo(false);
  };

  return (
    <Wrapper id={PATHS.MISSION.slice(1)}>
      {playVideo && (
        <>
          <CloseIcon onClick={handleClose} />
          <WrapperVideo>
            <ReactPlayer
              url={'https://vimeo.com/725345897/725ebd869c'}
              width="100%"
              height="100%"
              playing={playVideo}
              controls
              onEnded={() => {
                setPlayVideo(false);
              }}
            />
          </WrapperVideo>
        </>
      )}

      {!playVideo && (
        <WrapperBackground>
          <SectionHomepageContainer>
            <WrapperText>
              <Title>{t('OURMISSION:TITLE')}</Title>
              <Subtitle>{t('OURMISSION:SUBTITLE')}</Subtitle>
              <Subtitle>{t('OURMISSION:SUBTITLE_TWO')}</Subtitle>
            </WrapperText>
            <ContentImage>
              <WrapperIcon
                onClick={() => {
                  handleTrackPlayVideo(true);
                  setPlayVideo(true);
                }}
              >
                <Icon src={play} alt="Play icon" />
              </WrapperIcon>
            </ContentImage>
          </SectionHomepageContainer>
        </WrapperBackground>
      )}
    </Wrapper>
  );
};

export default OurMision;
