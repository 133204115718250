import { Link } from 'gatsby';
import styled from 'styled-components';

import { SIZES } from '@style/sizes';

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
`;

export const Title = styled.h2`
  @media (max-width: ${SIZES.TABLET_800}px) {
    font-size: 36px;
    padding: 0;
  }
  display: flex;
  justify-content: center;
  padding-top: 30px;
  width: 100%;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 46px;
  line-height: 52px;
  text-align: center;
  margin-bottom: 20px;
  color: ${(props) => props.theme.palette.primary};
`;

export const SubTitle = styled.p`
  @media (max-width: ${SIZES.TABLET_800}px) {
    max-width: 500px;
  }
  max-width: 860px;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 20px;
  padding-bottom: 40px;
  line-height: 30px;
  text-align: center;
  color: ${(props) => props.theme.palette.septernary};
`;

export const SectionWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_800}px) {
    flex-direction: column;
  }
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
  gap: 20px;
`;

export const ImageWrapper = styled.div`
  flex: 2;
  margin: 0 10px;
`;

export const DescriptionText = styled.div`
  justify-content: end;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  padding-left: 20px;
`;

export const DemoButton = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.common.green};
  color: ${(props) => props.theme.palette.primary};
  width: 190px;
  border-radius: 10px;
  height: 40px;
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => props.theme.palette.sexternary};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`;

export const BulletWrapper = styled.div`
  padding: 10px;
`;

export const Bullet = styled.div`
  background-color: ${(props) => props.theme.palette.common.green};
  height: 8px;
  width: 8px;
  border-radius: 50px;
`;

export const BulletText = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  color: ${(props) => props.theme.palette.septernary};
  font-size: 18px;
  line-height: 28px;
  text-align: left;
`;

export const BackgroundSquare = styled.div`
  height: 385px;
  width: 900px;
  position: absolute;
  background-color: ${(props) => props.theme.palette.quinternary};
  border-radius: 20px;
  right: 50%;
  top: -5px;
`;

export const DescriptionImg = styled.img`
  width: 100%;
  position: relative;
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
`;
