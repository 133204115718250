import useIsClient from 'hooks/useIsClient';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SectionContent from '@components/Homepage/SolutionsNavigator/Content';
import { ISectionTabsProps } from '@components/Homepage/SolutionsNavigator/Tabs/types';
import { SectionHomepageContainer } from '@components/Layout/Layout.styles';

import { PATHS } from '@global/urls';
import { SectionValues } from '@helpers/enum/SectionValues';
import { TabsUnstyled } from '@mui/base';

import {
  ContentWrapper,
  Icon,
  Selected,
  SelectedIcon,
  SubTitle,
  SubTitleWrapper,
  Tab,
  TabItems,
  TabsListMobile,
  TabSectionWrapper,
  TabsWrapper,
  TabValue,
  TextWrapper,
  Title,
  TitleWrapper,
  WrapperIcon,
  WrapperText,
} from './styles';

const MobileSectionTabs = (props: ISectionTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<SectionValues>(SectionValues.EXPERT_KNOWLEDGE);
  const { t } = useTranslation();
  const { icons, content, selected, notSelected, handleTrackTabChange, handleTrackButton } = props;
  const { key } = useIsClient();
  const handleIcon = (currentTab: SectionValues) => {
    const iconTab = icons.find((icon) => icon.tabName === currentTab);
    return iconTab!.tabName === selectedTab ? iconTab!.active : iconTab!.inActive;
  };

  const handleTabChange = (event: React.SyntheticEvent, value: number | string) => {
    handleTrackTabChange(selectedTab, value as SectionValues);
    setSelectedTab(value as SectionValues);
  };

  const tabs = [
    {
      tabValue: SectionValues.EXPERT_KNOWLEDGE,
      icon: handleIcon(SectionValues.EXPERT_KNOWLEDGE),
    },
    {
      tabValue: SectionValues.STRATEGIC_DATA,
      icon: handleIcon(SectionValues.STRATEGIC_DATA),
    },
    {
      tabValue: SectionValues.DYNAMIC_MATERIALITY,
      icon: handleIcon(SectionValues.DYNAMIC_MATERIALITY),
    },
    {
      tabValue: SectionValues.REPORTING,
      icon: handleIcon(SectionValues.REPORTING),
    },
  ];

  return (
    <SectionHomepageContainer>
      <ContentWrapper key={key} id={PATHS.SUSTAINABILITY.slice(1)}>
        <TabsUnstyled>
          <TabSectionWrapper>
            <TextWrapper>
              <TitleWrapper>
                <Title>{t('SECTION_VALUES:TITLE')}</Title>
              </TitleWrapper>
              <SubTitleWrapper>
                <SubTitle>{t('SECTION_VALUES:SUBTITLE')}</SubTitle>
              </SubTitleWrapper>
            </TextWrapper>
            <TabsWrapper>
              <TabsListMobile>
                {tabs.map((element) => {
                  return (
                    <>
                      <TabItems>
                        <Tab
                          value={element.tabValue}
                          isSelected={selectedTab === element.tabValue}
                          onChange={handleTabChange}
                        >
                          <WrapperIcon>
                            <Icon src={element.icon} alt="" />
                          </WrapperIcon>
                          <WrapperText>
                            <TabValue>{element.tabValue}</TabValue>
                          </WrapperText>
                          {selectedTab === element.tabValue ? (
                            <SelectedIcon>
                              <Selected src={selected} alt="" />
                            </SelectedIcon>
                          ) : (
                            <SelectedIcon>
                              <Selected src={notSelected} alt="" />
                            </SelectedIcon>
                          )}
                        </Tab>
                      </TabItems>
                      {selectedTab === element.tabValue ? (
                        <SectionContent
                          content={content.find((res) => res.selectedTab === selectedTab)!}
                          isMobile
                          handleTrackButton={handleTrackButton}
                        />
                      ) : null}
                    </>
                  );
                })}
              </TabsListMobile>
            </TabsWrapper>
          </TabSectionWrapper>
        </TabsUnstyled>
      </ContentWrapper>
    </SectionHomepageContainer>
  );
};
export default MobileSectionTabs;
