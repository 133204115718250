import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SectionContent from '@components/Homepage/SolutionsNavigator/Content';
import { SectionHomepageContainer } from '@components/Layout/Layout.styles';

import { PATHS } from '@global/urls';
import { SectionValues } from '@helpers/enum/SectionValues';
import { TabsUnstyled } from '@mui/base';

import {
  Tab,
  TabList,
  WrapperIcon,
  WrapperText,
  TabValue,
  TitleWrapper,
  SubTitleWrapper,
  TabsWrapper,
  TabSectionWrapper,
  Title,
  SubTitle,
  SelectedTab,
  TextWrapper,
  TabItems,
  ContentWrapper,
  Icon,
} from './styles';
import { ISectionTabsProps } from './types';

const SectionTabs = (props: ISectionTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<SectionValues>(SectionValues.EXPERT_KNOWLEDGE);
  const { t } = useTranslation();
  const { icons, content, handleTrackTabChange, handleTrackButton } = props;

  const handleIcon = (currentTab: SectionValues) => {
    const iconTab = icons.find((icon) => icon.tabName === currentTab);
    return iconTab!.tabName === selectedTab ? iconTab!.active : iconTab!.inActive;
  };

  const tabs = [
    {
      tabValue: SectionValues.EXPERT_KNOWLEDGE,
      icon: handleIcon(SectionValues.EXPERT_KNOWLEDGE),
    },
    {
      tabValue: SectionValues.STRATEGIC_DATA,
      icon: handleIcon(SectionValues.STRATEGIC_DATA),
    },
    {
      tabValue: SectionValues.DYNAMIC_MATERIALITY,
      icon: handleIcon(SectionValues.DYNAMIC_MATERIALITY),
    },
    {
      tabValue: SectionValues.REPORTING,
      icon: handleIcon(SectionValues.REPORTING),
    },
  ];

  const handleTabChange = (event: React.SyntheticEvent, value: number | string) => {
    handleTrackTabChange(selectedTab, value as SectionValues);
    setSelectedTab(value as SectionValues);
  };

  return (
    <SectionHomepageContainer>
      <ContentWrapper id={PATHS.SUSTAINABILITY.slice(1)}>
        <TabsUnstyled defaultValue={SectionValues.EXPERT_KNOWLEDGE}>
          <TabSectionWrapper>
            <TextWrapper>
              <TitleWrapper>
                <Title>{t('SECTION_VALUES:TITLE')}</Title>
              </TitleWrapper>
              <SubTitleWrapper>
                <SubTitle>{t('SECTION_VALUES:SUBTITLE')}</SubTitle>
              </SubTitleWrapper>
            </TextWrapper>
            <TabsWrapper>
              <TabList>
                {tabs.map((element, index) => {
                  return (
                    <TabItems>
                      <Tab
                        isLastChild={tabs.length - 1 === index}
                        value={element.tabValue}
                        isSelected={selectedTab === element.tabValue}
                        onChange={handleTabChange}
                      >
                        <WrapperIcon>
                          <Icon src={element.icon} alt="" />
                        </WrapperIcon>
                        <WrapperText>
                          <TabValue>{element.tabValue}</TabValue>
                        </WrapperText>
                      </Tab>
                      <SelectedTab isSelected={selectedTab === element.tabValue} />
                    </TabItems>
                  );
                })}
              </TabList>
            </TabsWrapper>
          </TabSectionWrapper>
        </TabsUnstyled>
        <SectionContent
          content={content.find((res) => res.selectedTab === selectedTab)!}
          handleTrackButton={handleTrackButton}
        />
      </ContentWrapper>
    </SectionHomepageContainer>
  );
};
export default SectionTabs;
