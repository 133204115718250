import styled from 'styled-components';

import { ITabProps } from '@components/Homepage/SolutionsNavigator/Tabs/types';

import { TabsListUnstyled, TabUnstyled, tabUnstyledClasses } from '@mui/base';
import { SIZES } from '@style/sizes';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1380px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
`;

export const Tab = styled(TabUnstyled)<ITabProps>`
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  box-shadow: ${(props) => props.theme.boxShadow.module} !important;
  background-color: ${(props) => props.theme.palette.common.white};
  border: unset;
  border-radius: 10px;
  color: ${(props) => props.theme.palette.secondary};
  cursor: pointer;
  ${(props) => !props.isSelected && ``}
  &.${tabUnstyledClasses.selected} {
    background-color: ${(props) => props.theme.palette.common.white};
    color: ${(props) => props.theme.palette.secondary};
  }
`;

export const TabsListMobile = styled(TabsListUnstyled)`
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const WrapperIcon = styled.div`
  width: 30px;
  height: 30px;
  padding-right: 15px;
`;

export const WrapperText = styled(TabsListUnstyled)`
  width: 100%;
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
`;

export const TabValue = styled.p`
  font-size: 20px;
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
`;

export const WrapperTable = styled.div`
  width: 100%;
  min-height: 300px;
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 0 0 16px 16px;
  z-index: 3;
  box-sizing: border-box;
`;

export const WrapperContent = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    display: flex;
    flex-direction: column;
  }
  width: 100%;
  min-height: 200px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.common.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 50px;
`;

export const TitleWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_800}px) {
    max-width: 400px;
  }
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 36px;
  line-height: 41px;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  color: ${(props) => props.theme.palette.primary};
`;

export const SubTitle = styled.p`
  font-size: 20px;
  width: 100%;
  line-height: 28px;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  color: ${(props) => props.theme.palette.septernary};
`;

export const SubTitleWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_800}px) {
    max-width: 500px;
    padding: 20px;
  }
  display: flex;
  justify-content: center;
  padding: 20px 0;
  align-items: center;
`;

export const TabSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TabsWrapper = styled.div`
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
`;

export const SelectedTab = styled.div<ITabProps>`
  border-bottom: 5px solid
    ${(props) => (props.isSelected ? props.theme.palette.common.green : 'none')};
  width: 150px;
  height: 4px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  display: flex;
  justify-content: center;
`;

export const TabItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 23px;
`;

export const Icon = styled.img``;

export const SelectedIcon = styled.div``;

export const Selected = styled.img``;
