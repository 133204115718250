import { GatsbyImage } from 'gatsby-plugin-image';
import useIsClient from 'hooks/useIsClient';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';

import arrowDown from '@assets/Homepage/arrowDownHero.svg';
import logo from '@assets/Homepage/hero_logo.svg';
import heroVideo from '@assets/Homepage/hero_motion.mp4';
import { SIZES } from '@style/sizes';

import {
  ArrowDown,
  HeroSubtitle,
  HeroTitle,
  LogoImage,
  Wrapper,
  WrapperImage,
  WrapperText,
  WrapperTitle,
  WrapperVideo,
  Text,
  WrapperBackground,
  WrapperLogo,
} from './Hero.styles';
import { IHeroProps } from './Hero.types';

const Hero = (props: IHeroProps) => {
  const { logoMobile, backgroundMobile } = props;
  const { t } = useTranslation();
  const { key } = useIsClient();
  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.MOBILE_575}px)`,
  });

  return (
    <Wrapper key={key}>
      <WrapperTitle>
        <HeroTitle>{t('HERO:TITLE')}</HeroTitle>
        <HeroSubtitle>{t('HERO:SUBTITLE')}</HeroSubtitle>
      </WrapperTitle>
      {!isMobile && (
        <>
          <WrapperVideo>
            <ReactPlayer
              url={heroVideo}
              width="100%"
              height="100%"
              playing
              loop
              muted
              playsinline
            />
          </WrapperVideo>
          <WrapperImage>
            <LogoImage image={logo} />
          </WrapperImage>
        </>
      )}
      {isMobile && (
        <WrapperBackground>
          <GatsbyImage alt="texture" image={backgroundMobile} />
          <WrapperLogo>
            <GatsbyImage alt="logo-motive" image={logoMobile} />
          </WrapperLogo>
        </WrapperBackground>
      )}
      <WrapperText>
        <Text>{t('HERO:TEXT')}</Text>
        <ArrowDown src={arrowDown} alt="Arrow down" />
      </WrapperText>
    </Wrapper>
  );
};

export default Hero;
