import styled from 'styled-components';

import { CardMedia } from '@mui/material';
import { SIZES } from '@style/sizes';

export const Wrapper = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    height: 580px;
  }
  @media (max-width: ${SIZES.TABLET_768}px) {
    height: 670px;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    height: 690px;
    position: relative;
  }
  width: 100%;
  height: 670px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #072340;
  overflow: hidden;
`;

export const LogoImage = styled(CardMedia)`
  @media (max-width: ${SIZES.TABLET_992}px) {
    background-size: cover !important;
  }
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-repeat: unset !important;
`;

export const WrapperVideo = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    height: 340px;
  }
  height: 430px;
`;

export const WrapperImage = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    height: 610px;
    top: 30px;
  }
  @media (max-width: ${SIZES.TABLET_768}px) {
    height: 740px;
    top: 10px;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    height: 620px;
    top: 140px;
  }
  @media (max-width: ${SIZES.MOBILE_414}px) {
    top: 120px;
  }
  @media (max-width: ${SIZES.MOBILE_380}px) {
    height: 620px;
    top: 170px;
  }
  width: 100%;
  height: 800px;
  position: absolute;
  top: -40px;
  left: 0;
`;

export const HeroTitle = styled.h1`
  @media (max-width: ${SIZES.TABLET_992}px) {
    padding-top: 0;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    font-size: 42px;
    line-height: 48px;
    padding: 0 10px;
  }
  font-size: 48px;
  line-height: 56px;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: 20px;
  text-align: center;
  padding-top: 60px;
`;

export const HeroSubtitle = styled.p`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    line-height: 28px;
  }
  font-size: 24px;
  line-height: 24px;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  color: #8db7be;
  text-align: center;
`;

export const WrapperTitle = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    position: absolute;
    top: 0;
  }
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 40px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;

export const WrapperText = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    bottom: 70px;
  }
  @media (max-width: ${SIZES.TABLET_992}px) {
    bottom: 0px;
    top: -60px;
  }
  @media (max-width: ${SIZES.TABLET_768}px) {
    top: 10px;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    position: absolute;
    top: unset;
    bottom: 30px;
  }
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 12;
  bottom: 50px;
`;

export const ArrowDown = styled.img`
  width: 32px;
  height: 30px;
`;

export const Text = styled.p`
  font-size: 24px;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.regular};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: 20px;
`;

export const WrapperBackground = styled.div`
  width: 100%;
`;

export const WrapperLogo = styled.div`
  @media (max-width: ${SIZES.MOBILE_380}px) {
    bottom: 120px;
  }
  width: 100%;
  position: absolute;
  bottom: 90px;
`;
