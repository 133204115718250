import styled from 'styled-components';

import background from '@assets/Homepage/backgroundOurMission.svg';
import CancelIcon from '@mui/icons-material/Cancel';

export const Wrapper = styled.div`
  width: 100%;
  height: 618px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  position: relative;
  background-position-x: center;
`;
export const WrapperBackground = styled.div`
  width: 100%;
  height: 618px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperText = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 48px;
  color: ${(props) => props.theme.palette.common.white};
  line-height: 55px;
  margin-bottom: 20px;
`;

export const Subtitle = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 20px;
  color: ${(props) => props.theme.palette.common.white};
  text-align: center;
  line-height: 28px;
`;

export const WrapperIcon = styled.div`
  width: 78px;
  height: 78px;
  cursor: pointer;
`;

export const ContentImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

export const WrapperVideo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const CloseIcon = styled(CancelIcon)`
  z-index: 3;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.secondary};
  :hover {
    color: ${(props) => props.theme.palette.common.white};
  }
`;
