import { graphql } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { IHomepageData } from 'types/homepage';
import { IInsight } from 'types/insight';

import { ContactUsDetails } from '@components/ContactUsForm/types.ContactUsForm';
import GetStarted from '@components/Homepage/GetStarted';
import Hero from '@components/Homepage/Hero';
import Insights from '@components/Homepage/InsightsSlider';
import OurMission from '@components/Homepage/OurMission';
import MobileSectionTabs from '@components/Homepage/SolutionsNavigator/MobileTabs';
import SectionTabs from '@components/Homepage/SolutionsNavigator/Tabs';
import Layout from '@components/Layout';
import { SectionHomepageContainer } from '@components/Layout/Layout.styles';
import UserMessage from '@components/Layout/UserMessage';
import SEO from '@components/SEO';

import dynamicMateriality from '@assets/Homepage/dynamic.png';
import dynamic from '@assets/Homepage/dynamic.svg';
import dynamicActive from '@assets/Homepage/dynamicActive.svg';
import expertKnowledge from '@assets/Homepage/expert.png';
import expert from '@assets/Homepage/expert.svg';
import expertActive from '@assets/Homepage/expertActive.svg';
import mobileNotSelected from '@assets/Homepage/mobileNotSelected.svg';
import mobileSelectedIcon from '@assets/Homepage/mobileSelectedIcon.svg';
import reporting from '@assets/Homepage/reporting.png';
import reportingActive from '@assets/Homepage/reportingActive.svg';
import reportingIcon from '@assets/Homepage/reportingIcon.svg';
import strategicData from '@assets/Homepage/strategic.png';
import strategic from '@assets/Homepage/strategic.svg';
import strategicActive from '@assets/Homepage/strategicActive.svg';
import { PATHS } from '@global/urls';
import { post } from '@helpers/api';
import { SectionValues } from '@helpers/enum/SectionValues';
import { UserMessageType } from '@helpers/enum/UserMessageTypes';
import { getImageData, ParseImages } from '@helpers/imageHelper';
import { hasWindow, trackEventAnalytics } from '@helpers/window';
import { SIZES } from '@style/sizes';

function IndexPage(props: IHomepageData) {
  const { data } = props;
  const images = ParseImages(data);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [userMessageType, setUserMessageType] = useState(UserMessageType.SUCCESS);

  const onSubmit = async (userDetails: ContactUsDetails) => {
    try {
      setLoading(true);
      await post('users/contact-us', { ...userDetails, isMarketingEmail: true });
      setLoading(false);
      setOpenModal(false);
      setUserMessageType(UserMessageType.SUCCESS);
      setUserMessage('The email was sent correctly');
    } catch (error) {
      setLoading(false);
      setOpenModal(false);
      setUserMessageType(UserMessageType.FAIL);
      setUserMessage('There was a problem sending your email, please try again');
    }
  };
  const imageSectionTabs = [
    {
      tabName: SectionValues.EXPERT_KNOWLEDGE,
      active: expertActive,
      inActive: expert,
    },
    {
      tabName: SectionValues.STRATEGIC_DATA,
      active: strategicActive,
      inActive: strategic,
    },
    {
      tabName: SectionValues.DYNAMIC_MATERIALITY,
      active: dynamicActive,
      inActive: dynamic,
    },
    {
      tabName: SectionValues.REPORTING,
      active: reportingActive,
      inActive: reportingIcon,
    },
  ];
  const TabsContent = [
    {
      selectedTab: SectionValues.EXPERT_KNOWLEDGE,
      image: expertKnowledge,
      subTitle: t('SECTION_CONTENT:EXPERT_KNOWLEDGE'),
      firstBullet: t('SECTION_CONTENT:EXPERT_KNOWLEDGE_FIRST_BULLET'),
      secondBullet: t('SECTION_CONTENT:EXPERT_KNOWLEDGE_SECOND_BULLET'),
      thirdBullet: t('SECTION_CONTENT:EXPERT_KNOWLEDGE_THIRD_BULLET'),
      link: PATHS.ABOUT_US,
    },
    {
      selectedTab: SectionValues.STRATEGIC_DATA,
      image: strategicData,
      subTitle: t('SECTION_CONTENT:STRATEGIC_DATA'),
      firstBullet: t('SECTION_CONTENT:STRATEGIC_DATA_FIRST_BULLET'),
      secondBullet: t('SECTION_CONTENT:STRATEGIC_DATA_SECOND_BULLET'),
      thirdBullet: t('SECTION_CONTENT:STRATEGIC_DATA_THIRD_BULLET'),
      link: PATHS.SOLUTION,
    },
    {
      selectedTab: SectionValues.DYNAMIC_MATERIALITY,
      image: dynamicMateriality,
      subTitle: t('SECTION_CONTENT:DYNAMIC_MATERIALITY'),
      firstBullet: t('SECTION_CONTENT:DYNAMIC_MATERIALITY_FIRST_BULLET'),
      secondBullet: t('SECTION_CONTENT:DYNAMIC_MATERIALITY_SECOND_BULLET'),
      thirdBullet: t('SECTION_CONTENT:DYNAMIC_MATERIALITY_THIRD_BULLET'),
      link: PATHS.SOLUTION,
    },
    {
      selectedTab: SectionValues.REPORTING,
      image: reporting,
      subTitle: t('SECTION_CONTENT:REPORTING'),
      firstBullet: t('SECTION_CONTENT:REPORTING_FIRST_BULLET'),
      secondBullet: t('SECTION_CONTENT:REPORTING_SECOND_BULLET'),
      thirdBullet: t('SECTION_CONTENT:REPORTING_THIRD_BULLET'),
      link: PATHS.SOLUTION,
    },
  ];

  const blogs = data.cms.blogs.data;

  const insights: IInsight[] = [];
  blogs.forEach((bl) => {
    if (bl.attributes.Title !== undefined) {
      insights.push({
        title: bl.attributes.Title,
        image: bl.attributes.CoverImage.data.attributes.url,
        path: bl.attributes.path,
      });
    }
  });

  const handleTrackTabChange = (oldTab: SectionValues, newTab: SectionValues) => {
    trackEventAnalytics('Change Tab ', { oldTab, newTab, route: PATHS.INDEX });
  };

  const handleTrackButton = (tabSelected: SectionValues, link: string) => {
    trackEventAnalytics('Click Learn More Button into Tab Module ', {
      tabSelected,
      link,
      route: PATHS.INDEX,
    });
  };

  const handleTrackButtonViewAllInsight = () => {
    trackEventAnalytics('Click View All Insight', { route: PATHS.INDEX });
  };

  const handleTrackPlayVideo = (isPlay: boolean) => {
    if (isPlay) {
      trackEventAnalytics('Click Play Video Our Mission Module', { route: PATHS.INDEX });
    } else {
      trackEventAnalytics('Click Close Video Our Mission Module', { route: PATHS.INDEX });
    }
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.TABLET_992}px)`,
  });
  useEffect(() => {
    if (userMessage !== '') {
      if (hasWindow()) {
        window.scrollTo(0, 0);
      }
      setTimeout(() => {
        setUserMessage('');
      }, 3000);
    }
  }, [userMessage]);

  return (
    <Layout footerLogo={getImageData(images, 'footer')} currentRoute={PATHS.INDEX}>
      <SEO metaDescription={t('SEO:METADESCRIPTION')} metaTitle={t('SEO:METATITLE')} />
      {userMessage !== '' && <UserMessage message={userMessage} messageType={userMessageType} />}
      <Hero
        backgroundMobile={getImageData(images, 'motive-texture-mobile')}
        logoMobile={getImageData(images, 'motive-hero-mobile')}
      />
      {!isMobile && (
        <SectionTabs
          icons={imageSectionTabs}
          content={TabsContent}
          handleTrackTabChange={handleTrackTabChange}
          handleTrackButton={handleTrackButton}
        />
      )}
      {isMobile && (
        <MobileSectionTabs
          icons={imageSectionTabs}
          content={TabsContent}
          selected={mobileSelectedIcon}
          notSelected={mobileNotSelected}
          handleTrackTabChange={handleTrackTabChange}
          handleTrackButton={handleTrackButton}
        />
      )}
      <GetStarted
        loading={loading}
        onSubmit={onSubmit}
        openModal={openModal}
        handleOpenModal={() => {
          trackEventAnalytics(
            !openModal ? 'Open Contact US Modal - Homepage' : 'Close Contact US Modal - Homepage',
            {
              route: PATHS.INDEX,
            },
          );
          setOpenModal(!openModal);
        }}
      />
      <SectionHomepageContainer>
        <Insights
          insights={insights}
          isInsightPage={false}
          titleCopy={t('INSIGHTS:TITLE')}
          handleTrackButton={handleTrackButtonViewAllInsight}
        />
      </SectionHomepageContainer>
      <OurMission handleTrackPlayVideo={handleTrackPlayVideo} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($language: String!) {
    cms {
      blogs(pagination: { limit: 5 }, sort: "publishedAt:desc") {
        data {
          id
          attributes {
            SubTitle
            Title
            Author
            path
            readTime
            SEO {
              MetaDescription
              MetaTitle
            }
            CoverImage {
              data {
                attributes {
                  url
                }
              }
            }
            publishedAt
            Body {
              ... on cms_ComponentBlogBodyText2 {
                __typename
                RichText
              }
              ... on cms_ComponentBlogSubscribe {
                __typename
                Subscribe
                Title
              }
              ... on cms_ComponentBlogImage {
                __typename
                id
                Image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                ClarificationImage
              }
              ... on cms_ComponentBlogClarification {
                __typename
                Clarification
                Title
              }
            }
            Summary {
              Title
              Bullet {
                BulletText
              }
            }
          }
        }
      }
    }
    allFile(
      filter: {
        relativeDirectory: {
          in: [
            "components/Layout/Footer/assets"
            "pages/solutions/assets"
            "components/Homepage/Hero/assets"
          ]
        }
      }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    locales: allLocale(
      filter: {
        ns: {
          in: [
            "topbar"
            "footer"
            "demoModal"
            "hero"
            "getStarted"
            "ourMission"
            "sectionValues"
            "sectionContent"
            "insights"
            "contactUs"
            "SEO"
          ]
        }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
