import React from 'react';
import { useTranslation } from 'react-i18next';

import { PATHS } from '@global/urls';
import { SectionValues } from '@helpers/enum/SectionValues';

import {
  BackgroundSquare,
  Bullet,
  BulletText,
  BulletWrapper,
  ButtonWrapper,
  ContentWrapper,
  DemoButton,
  DescriptionImg,
  DescriptionText,
  DescriptionWrapper,
  SectionWrapper,
  SubTitle,
  TextWrapper,
  Title,
  ImageWrapper,
  TitleWrapper,
  CustomLink,
} from './styles';
import { IContent } from './types';

const SectionContent = (props: IContent) => {
  const { content, isMobile, handleTrackButton } = props;
  const { t } = useTranslation();

  const handleClick = () => {
    handleTrackButton(content.selectedTab, content.link);
  };

  return (
    <ContentWrapper>
      <TitleWrapper>
        <Title>{content.selectedTab}</Title>
        <SubTitle>{content.subTitle}</SubTitle>
      </TitleWrapper>
      <SectionWrapper>
        {!isMobile && <BackgroundSquare />}
        <ImageWrapper>
          <DescriptionImg src={content.image} alt="" />
        </ImageWrapper>
        <DescriptionWrapper>
          <DescriptionText>
            <TextWrapper>
              <BulletWrapper>
                <Bullet />
              </BulletWrapper>
              <BulletText>{content.firstBullet}</BulletText>
            </TextWrapper>
            <TextWrapper>
              <BulletWrapper>
                <Bullet />
              </BulletWrapper>
              <BulletText>{content.secondBullet}</BulletText>
            </TextWrapper>
            <TextWrapper>
              <BulletWrapper>
                <Bullet />
              </BulletWrapper>
              <BulletText>{content.thirdBullet}</BulletText>
            </TextWrapper>
          </DescriptionText>
          <ButtonWrapper>
            <CustomLink to={content.link} onClick={handleClick}>
              <DemoButton>{t('SECTION_CONTENT:DEMO_BUTTON')}</DemoButton>
            </CustomLink>
          </ButtonWrapper>
        </DescriptionWrapper>
      </SectionWrapper>
    </ContentWrapper>
  );
};
export default SectionContent;
