import React from 'react';
import { useTranslation } from 'react-i18next';

import ContactUsForm from '@components/ContactUsForm';
import { SectionHomepageContainer } from '@components/Layout/Layout.styles';
import Modal from '@components/Modal';

import arrowLeft from '@assets/Homepage/backgroundArrowLeft.svg';
import arrowRight from '@assets/Homepage/backgroundArrowRight.svg';

import {
  BackgroundArrow,
  BeginHere,
  Subtitle,
  Title,
  Wrapper,
  WrapperButtons,
  WrapperContent,
  WrapperSubtitle,
  WrapperTitle,
} from './GetStarted.styles';
import { IGetStartedProps } from './GetStarted.types';

const GetStarted = (props: IGetStartedProps) => {
  const { handleOpenModal, loading, onSubmit, openModal } = props;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Modal open={openModal} width={500} onClose={handleOpenModal}>
        <ContactUsForm loading={loading} onSubmit={onSubmit} />
      </Modal>
      <BackgroundArrow postion="left" src={arrowLeft} alt="Left arrow" />
      <WrapperContent>
        <SectionHomepageContainer>
          <WrapperTitle>
            <Title>{t('GETSTARTED:TITLE')}</Title>
          </WrapperTitle>
          <WrapperSubtitle>
            <Subtitle>{t('GETSTARTED:SUBTITLE')}</Subtitle>
          </WrapperSubtitle>
          <WrapperButtons>
            <BeginHere onClick={handleOpenModal}>{t('GETSTARTED:BUTTON_COPY')}</BeginHere>
          </WrapperButtons>
        </SectionHomepageContainer>
      </WrapperContent>
      <BackgroundArrow postion="right" src={arrowRight} alt="Right arrow" />
    </Wrapper>
  );
};

export default GetStarted;
